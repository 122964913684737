/*---------------------------------------------------------------FONTS---------------------------------------------------------------*/
//@font-face {
//	font-family: "montserat";
//	font-style: normal;
//	font-weight: 100;
//	font-display: block;
//
//	src: 	url("../fonts/montserat/light/Montserrat-Light.woff2") format("woff2"),
//	url("../fonts/montserat/light/Montserrat-Light.woff") format("woff"),
//	url("../fonts/montserat/light/Montserrat-Light.ttf") format("truetype"),
//	url("../fonts/montserat/light/Montserrat-Light.eot"),
//	url("../fonts/montserat/light/Montserrat-Light.eot?#iefix") format("embedded-opentype");
//}



@font-face {
	font-family: 'Formular';
	font-display: block;
	font-weight: 900;
	font-style: italic;
	src: url('../fonts/formular/Formular-BlackItalic.eot'),
	url('../fonts/formular/Formular-BlackItalic.eot?#iefix') format('embedded-opentype'),
	url('../fonts/formular/Formular-BlackItalic.woff2') format('woff2'),
	url('../fonts/formular/Formular-BlackItalic.woff') format('woff'),
	url('../fonts/formular/Formular-BlackItalic.ttf') format('truetype');

}

@font-face {
	font-family: 'Formular';
	font-display: block;
	font-weight: 500;
	font-style: italic;
	src: url('../fonts/formular/Formular-MediumItalic.eot'),
	url('../fonts/formular/Formular-MediumItalic.eot?#iefix') format('embedded-opentype'),
	url('../fonts/formular/Formular-MediumItalic.woff2') format('woff2'),
	url('../fonts/formular/Formular-MediumItalic.woff') format('woff'),
	url('../fonts/formular/Formular-MediumItalic.ttf') format('truetype');

}

@font-face {
	font-family: 'Formular';
	font-display: block;
	font-weight: normal;
	font-style: italic;
	src: url('../fonts/formular/Formular-Italic.eot'),
	url('../fonts/formular/Formular-Italic.eot?#iefix') format('embedded-opentype'),
	url('../fonts/formular/Formular-Italic.woff2') format('woff2'),
	url('../fonts/formular/Formular-Italic.woff') format('woff'),
	url('../fonts/formular/Formular-Italic.ttf') format('truetype');

}

@font-face {
	font-family: 'Formular';
	font-display: block;
	font-weight: 300;
	font-style: italic;
	src: url('../fonts/formular/Formular-LightItalic.eot'),
	url('../fonts/formular/Formular-LightItalic.eot?#iefix') format('embedded-opentype'),
	url('../fonts/formular/Formular-LightItalic.woff2') format('woff2'),
	url('../fonts/formular/Formular-LightItalic.woff') format('woff'),
	url('../fonts/formular/Formular-LightItalic.ttf') format('truetype');

}

@font-face {
	font-family: 'Formular';
	font-display: block;
	font-weight: 900;
	font-style: normal;
	src: url('../fonts/formular/Formular-Black.eot'),
	url('../fonts/formular/Formular-Black.eot?#iefix') format('embedded-opentype'),
	url('../fonts/formular/Formular-Black.woff2') format('woff2'),
	url('../fonts/formular/Formular-Black.woff') format('woff'),
	url('../fonts/formular/Formular-Black.ttf') format('truetype');

}

@font-face {
	font-family: 'Formular';
	font-display: block;
	font-weight: 300;
	font-style: normal;
	src: url('../fonts/formular/Formular-Light.eot'),
	url('../fonts/formular/Formular-Light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/formular/Formular-Light.woff2') format('woff2'),
	url('../fonts/formular/Formular-Light.woff') format('woff'),
	url('../fonts/formular/Formular-Light.ttf') format('truetype');

}

@font-face {
	font-family: 'Formular';
	font-display: block;
	font-weight: normal;
	font-style: normal;
	src: url('../fonts/formular/Formular.eot'),
	url('../fonts/formular/Formular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/formular/Formular.woff2') format('woff2'),
	url('../fonts/formular/Formular.woff') format('woff'),
	url('../fonts/formular/Formular.ttf') format('truetype');

}

@font-face {
	font-family: 'Formular';
	font-display: block;
	font-weight: 500;
	font-style: normal;
	src: url('../fonts/formular/Formular-Medium.eot'),
	url('../fonts/formular/Formular-Medium.eot?#iefix') format('embedded-opentype'),
	url('../fonts/formular/Formular-Medium.woff2') format('woff2'),
	url('../fonts/formular/Formular-Medium.woff') format('woff'),
	url('../fonts/formular/Formular-Medium.ttf') format('truetype');

}

@font-face {
	font-family: 'Formular';
	font-display: block;
	font-weight: bold;
	font-style: italic;
	src: url('../fonts/formular/Formular-BoldItalic.eot'),
	url('../fonts/formular/Formular-BoldItalic.eot?#iefix') format('embedded-opentype'),
	url('../fonts/formular/Formular-BoldItalic.woff2') format('woff2'),
	url('../fonts/formular/Formular-BoldItalic.woff') format('woff'),
	url('../fonts/formular/Formular-BoldItalic.ttf') format('truetype');

}

@font-face {
	font-family: 'Formular';
	font-weight: bold;
	font-style: normal;
	font-display: block;
	src: url('../fonts/formular/Formular-Bold.eot'),
	url('../fonts/formular/Formular-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/formular/Formular-Bold.woff2') format('woff2'),
	url('../fonts/formular/Formular-Bold.woff') format('woff'),
	url('../fonts/formular/Formular-Bold.ttf') format('truetype');

}