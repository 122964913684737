.stages-development{
    padding-top: toVw(115);
    background-color: #F8F8F8;
    position: relative;
    @include xmd-block(){
        padding-top: toVw(50);
    }
    @include xxs-block(){
        padding-top: toVw(20);
    }
    &__wrp{
        .advantages-top{
            @include xmd-block(){
                flex-wrap: wrap;
                margin-bottom: toVw(30);
            }
            .zag{
                @include xmd-block(){
                    width: 100%;
                    margin-bottom: toVw(20);
                }
            }

        }
    }
    &__items{
        padding-bottom: toVw(205);
        @include xmd-block(){
            padding-bottom: toVw(100);
        }
        @include xxs-block(){
            padding-bottom: 0;
            margin-bottom: toVw(40);
        }
        .slick-track {
            left: toVw(-40);

            @include sm-block() {
                left: 0;
            }
        }
        &.slick-slider{
            @include xxs-block(){
                margin-bottom: toVw(40);
            }
        }
        .slick-dots{
            bottom: toVw(-30);
            li{
                background-color: $blue;
                border-radius: 50%;
                margin: 0 10px;
                width: toVw(15);
                height: toVw(15);
            }
            button:before{
                content: none;
            }
            .slick-active{
                opacity: 0.4;
            }
        }

    }
    &__item{
        display: flex !important;
        align-items: flex-start;
        @include xxs-block(){
            padding: 0 toVw(20);
        }
        .num{
            @include font(100);
            color: $blue;
            line-height: 0.8;
            @include xxs-block(){
                @include font(80)
            }
        }
        .desc{
            padding-left: toVw(55);
            max-width: toVw(375);
            @include sm-block(){
                padding-left: toVw(20);
            }
            &-zag{
                @include font(25);
                font-weight: 500;
                margin-bottom: toVw(20);
            }
            &-info{
                color: #3D3D3D;
            }
        }
    }
    &__arrs{
        display: flex;
        align-items: center;
        position: absolute;
        left: toVw(140);
        bottom: toVw(100);
        @include xmd-block(){
            bottom: toVw(15);
            left: toVw(55);
        }
        @include xxs-block(){
            display: none;
        }
        .arr{
            width: toVw(55);
            height: toVw(55);
            border-radius: 50%;
            background-color: $blue;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: .3s;
            border: 3px solid $blue;
            cursor: pointer;
            margin-right: toVw(10);
            &:last-child{
                margin-right: 0;
            }
            &:hover{
                background-color: #fff;
                .icon{
                    fill: $blue;
                }
            }
            .icon{
                fill: #fff;

            }
        }

    }
}