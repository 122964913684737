$grid-columns: 12;
@for $i from 1 through $grid-columns {
	.col-#{$i}{
		width: percentage($i / $grid-columns);
	}
}

html{
	font-size: toVw(16);
}


// Стили
body {
	font-family: $font;
	font-weight: 400;
	overflow: visible;
	overflow-x: hidden;
	line-height: 1.2;
	font-size: toVw($fontSizeMain);
	margin: 0;
	color: $black;
	&._overflow{
		overflow: hidden;
	}
}
main{
	width: 100%;
	margin: 0 auto;
	position: relative;
}
a{
	text-decoration: none;
	transition: .2s;
	color: #131313;
}
img{
	max-width: 100%;
}
.tabs__content {
	display: none; /* по умолчанию прячем все блоки */
}
.tabs__content.active {
	display: flex; /* по умолчанию показываем нужный блок */
}


.container{
	@include wrapper();
}
ul{
	&._glob{
		li{
			position: relative;
			@include font(17);
			color: $text;
			padding-left: toVw(15);
			margin-bottom: toVw(15);
			transition: .3s;
			&:before{
				content: "";
				position: absolute;
				top: toVw(6);
				left: toVw(2);
				width: toVw(6);
				height: toVw(6);
				background-color: $blue;
				border-radius: 50%;
			}


		}
	}
	&._white{
		li{
			position: relative;
			@include font(18);
			color: $white;
			margin-bottom: toVw(30);
			padding-left: toVw(15);

			transition: .3s;
			@include xxs-block(){
				margin-bottom: toVw(20);
			}
			&:last-child{
				margin-bottom: 0;
			}
			&:before{
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: toVw(2);
				width: toVw(5);
				height: toVw(5);
				background-color: $white;
				border-radius: 50%;
			}
		}
	}
	&._indivisual{
		li{
			span{
				@include font(15);
			}
		}

	}

}