.examples-solutions{
    background-color: $blue;
    position: relative;
    padding-top: toVw(110);
    padding-bottom: toVw(120);
    @include xmd-block(){
        padding-top: toVw(50);
        padding-bottom: toVw(50);
    }
    @include sm-block(){
        padding-top: toVw(30);
        padding-bottom: toVw(30);
    }
    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../img/noise.png");
        background-size: 15px;
        background-repeat: repeat;
    }
    &__wrp{
        z-index: 1;
    }
    &__zag{
        @include font(20);
        color: $white;
        font-weight: 300;
        text-transform: uppercase;
        padding-left: toVw(140);
        margin-bottom: toVw(50);
        position: relative;
        z-index: 1;
        @include xmd-block(){
            padding-left: toVw(50);
            margin-bottom: toVw(20);
        }
        @include sm-block(){
            padding-left: toVw(30);
        }
    }
    &__item{
        padding: 0 toVw(140);
        margin-bottom: toVw(90);
        @include xmd-block(){
            padding: 0 toVw(50);
            margin-bottom: toVw(50);
        }
        @include sm-block(){
            padding: 0 toVw(30);
            margin-bottom: toVw(30);
        }
        &:last-child{
            margin-bottom: 0;
        }
        &-title{
            @include font(60);
            font-weight: 500;
            color: $white;
            margin-bottom: toVw(45);
            position: relative;
            z-index: 1;
            @include sm-block(){
                margin-bottom: toVw(20);
            }
            @include xxs-block(){
                @include font(32);
            }

        }
        &-body{
            border-top: 1px solid #333569;
            border-bottom: 1px solid #333569;
            padding: toVw(15) 0;
            z-index: 1;
            position: relative;
            @include xmd-block(){
                padding: toVw(30) 0;
            }
            @include sm-block(){
                padding: toVw(20) 0;
                display: flex;
                flex-wrap: wrap;
            }
            .list{
                display: flex;
                align-items: flex-start;
                padding: toVw(40) 0;
                transition: .3s;
                @include xmd-block(){
                    padding: toVw(30);
                    flex-wrap: wrap;
                }
                @include sm-block(){
                    width: 50%;
                }
                @include xxs-block(){
                    width: 100%;
                }
                &:hover{
                    .name{
                        padding-left: toVw(10);
                        @include sm-block(){
                            padding-left: 0;
                        }
                    }
                }

                .name{
                    width: 50%;
                    @include font(25);
                    color: $white;
                    transition: .3s;
                    @include xmd-block(){
                        width: 100%;
                        margin-bottom: toVw(20);
                    }

                    @include xxs-block(){
                        @include font(30);
                    }
                }
                .desc{
                    display: flex;
                    justify-content: space-between;
                    width: 50%;
                    @include xmd-block(){
                        width: 100%;
                    }
                    @include sm-block(){
                        width: 100%;
                        flex-wrap: wrap;
                    }

                    .info{
                        width: 30%;
                        display: flex;
                        flex-wrap: wrap;
                        &:first-child{
                            width: 40%;
                            @include xxs-block(){
                                width: 100%;
                            }
                        }
                        @include sm-block(){
                            margin-bottom: toVw(20);
                            width: 100%;
                        }
                        @include xxs-block(){
                            width: 100%;
                            margin-bottom: toVw(20);
                        }
                        span{
                            @include font(14);
                            color: $white;
                            opacity: 0.7;
                            margin-bottom: toVw(15);
                            width: 100%;
                            @include sm-block(){
                                margin-bottom: toVw(5);
                            }
                        }
                        a{
                            @include font(18);
                            color: $white;
                            transition: .3s;
                            border-bottom: 1px solid transparent;
                            &:hover{
                                border-color: $white;
                            }
                        }
                        .price{
                            @include font(20);
                            opacity: 1;

                        }
                    }
                    .btn-white{
                        margin-left: 0;
                        width: 100%;
                        z-index: 1;
                    }

                }
            }
        }

        &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../img/noise.png");
            background-size: 14px;
            background-repeat: repeat;
        }
        &:after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #282B88;
            opacity: 0;
            transition: .3s;
        }

    }
    &._white{
        background-color: $white;
        &:before{
            content: none;
        }
        .examples-solutions__item{
            &:before{
                content: none;
            }
            &-title{
                color: $black;
            }
            &-body{
                border-color: #d1d1d1;
                .list{
                    .name{
                        color: $black;
                        font-weight: 500;
                    }
                    .desc{
                        .info{
                            &:first-child{
                                width: auto;
                            }
                            span{
                                color: $black;
                                opacity: 1;

                                &:first-child{
                                    text-transform: uppercase;
                                }
                                &:last-child{
                                    @include font(18);
                                    white-space: nowrap;

                                }
                            }
                            a{
                                color: $black;
                            }
                        }
                        .btn-white{
                            color: $blue;
                            border-color: $blue;
                            font-weight: 600;
                            &:hover{
                                background-color: $blue;
                                color: $white;
                            }
                        }
                    }
                }
            }
            &._col2{
                .examples-solutions__item-body{

                    @include sm-block(){
                        display: flex;
                        flex-wrap: wrap;
                    }
                    @include xxs-block(){
                        padding-bottom: 0;
                    }

                    .list{
                        @include sm-block(){
                            width: 50%;
                            margin-bottom: toVw(30);
                        }
                        @include xxs-block(){
                            width: 100%;
                        }
                        &:last-child{
                            @include xxs-block(){
                                margin-bottom: 0;
                            }
                        }
                        .name{
                            width: 35%;
                            @include xmd-block(){
                                width: 100%;
                            }
                        }
                        .desc{
                            width: 65%;
                            @include xmd-block(){
                                width: 100%;
                            }
                            @include sm-block(){
                                flex-wrap: wrap;
                            }
                            .info{
                                width: 100%;
                                margin-bottom: toVw(20);
                            }
                        }
                    }
                }
            }
        }
        .examples-solutions__zag{
            color: $black;
        }
        .examples-solutions__item-footer{
            @include font(18);
            font-weight: 700;
            padding: toVw(40) 0;
            border-bottom: 1px solid #d1d1d1;
            @include xxs-block(){
                padding: toVw(20) 0;
            }
        }

    }
}