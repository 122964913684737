.advantages{
    padding-top: toVw(100);
    padding-bottom: toVw(115);
    @include xmd-block(){
        padding-top: toVw(50);
        padding-bottom: toVw(50);
    }
    @include sm-block(){
        padding: toVw(30) 0;
    }
    @include xxs-block(){
        padding: toVw(20) 0;
    }
    &-wrp{
        //display: flex;
        //flex-wrap: wrap;
    }
    &-top{
        width: 100%;
        display: flex;
        align-items: baseline;
        margin-bottom: toVw(90);
        @include xmd-block(){
            margin-bottom: toVw(50);
        }
        @include sm-block(){
            margin-bottom: toVw(30);
            flex-wrap: wrap;

        }
        .zag{
            @include font(20);
            text-transform: uppercase;
            color: $text;
            width: 50%;
            @include sm-block(){
                width: 100%;
                margin-bottom: toVw(20);
            }
        }
        &__difference{
            width: 50%;
            @include sm-block(){
                width: 100%;
            }
            .title{
                @include font(25);
                color: $black;
                font-weight: bold;
                margin-bottom: toVw(15);
            }

            .subtitle{
                color: $black;
                max-width: toVw(805);
                p{
                    @include font(18);
                    margin-bottom: toVw(30);
                    @include sm-block(){
                        margin-bottom: toVw(20);
                    }
                    a{
                        border-bottom: 1px solid transparent;
                        transition: .3s;
                        &:hover{
                            border-color: #131313;
                        }
                    }
                }
            }
        }
        &._about{
            flex-wrap: wrap;
            margin-bottom: toVw(60);
            .zag{
                width: 100%;
                margin-bottom: toVw(20);
            }
            .advantages-top__difference{
                width: 100%;
                display: flex;
                justify-content: space-between;
                @include xmd-block(){
                    flex-wrap: wrap;
                }
                .title{
                    max-width: toVw(570);
                    @include xmd-block(){
                        max-width: 100%;
                    }
                }
                .subtitle{
                    @include xmd-block(){
                        max-width: 100%;
                    }
                }
            }
        }
    }
    &-items{
        display: flex;
        align-items: flex-start;
        width: 100%;
        @include xmd-block(){
            flex-wrap: wrap;
        }
        &._about{

        }
    }
    &-item{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 25%;
        &:hover{
            .title{
                color: #000;
            }
            ul{
                li{
                    color: $black;
                }
            }
            .advantages-item__img{
                transform: scale(1.05);
            }
        }
        @include xmd-block(){
            width: 50%;
            margin-bottom: toVw(50);
            &:nth-child(3),
            &:nth-child(4){
                margin-bottom: 0;
            }
        }
        @include sm-block(){
            margin-bottom: toVw(30);
            &:nth-child(3),
            &:nth-child(4){
                margin-bottom: 0;
            }
        }
        @include xxs-block(){
            width: 100%;
        }

        &__number{
            width: 100%;
            @include font(18);
            color: $black;
            margin-bottom: toVw(45);
            @include sm-block(){
                margin-bottom: toVw(15);
                padding-left: toVw(10);
            }
            @include xxs-block(){
                padding-left: 0;
            }

        }
        &__img{
            margin-bottom: toVw(45);
            padding-left: toVw(25);
            transition: .3s;
            @include sm-block(){
                margin-bottom: toVw(30);
            }
            @include xxs-block(){
                margin-bottom: toVw(10);
            }
            picture{
                //padding-bottom: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    //position: absolute;
                    //top: 0;
                    //left: 0;
                }
            }
        }
        &__info{
            max-width: toVw(350);
            .title{
                @include font(25);
                font-weight: 500;
                color: $text;
                margin-bottom: toVw(25);
                transition: .3s;
                @include xxs-block(){
                    @include font(20);
                    margin-bottom: toVw(20);
                }
            }
        }
    }
}