.norm-hour{
    width: 100%;
    padding: toVw(30) 0;
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    margin-bottom: toVw(120);
    @include xmd-block(){
        margin-bottom: toVw(50);
        padding: toVw(20) 0;
    }
    @include xxs-block(){
        margin: toVw(20) 0;
        padding: toVw(10) 0;
    }
    p{
        text-align: center;
        @include font(18);
        font-weight: 400;
        @include xxs-block(){
            @include font(16);
        }

    }
}