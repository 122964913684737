.achievements{
    background-color: #F8F8F8;
    padding: toVw(110) 0;
    @include xmd-block(){
        padding: toVw(50) 0;
    }
    @include xxs-block(){
        padding: toVw(20) 0;
    }
    &-name{
        @include font(20);
        font-weight: 300;
        text-transform: uppercase;
        color: $text;
        margin-bottom: toVw(85);
        @include xmd-block(){
            margin-bottom: toVw(50);
        }
        @include xxs-block(){
            margin-bottom: toVw(20);
        }
    }
    &-title{
        @include font(45);
        font-weight: 700;
        margin-bottom: toVw(100);
        @include xmd-block(){
            margin-bottom: toVw(30);
        }
        @include sm-block(){
            @include font(30);
        }
        @include xxs-block(){
            margin-bottom: toVw(20);
            @include font(25);
        }
    }
    &-items{
        display: flex;
        gap: toVwVal(60px);
        margin-bottom: toVw(45);
        padding: toVw(40) 0 toVw(20);
        border-top: 1px solid #E4E6EB;
        border-bottom: 1px solid #E4E6EB;
        @include xmd-block(){
            gap: toVwVal(20px);
            margin-bottom: toVw(20);
        }
        @include sm-block(){
            flex-wrap: wrap;
            gap: toVwVal(30px);
            padding: toVw(20) 0;
        }

    }
    &-item{
        width: calc(33.33% - (3.125vw / 5 * 4));
        @include xmd-block(){
            width: calc(33.33% - (1.04167vw / 5 * 4));
            margin-bottom: toVw(20);
        }
        @include sm-block(){
            width: calc(33.33% - (1.5625vw / 5 * 4));
            &:last-child{
                margin-bottom: 0;
            }
        }
        @include xxs-block(){
            margin-bottom: toVw(20);
            width: 100%
        }

        span{
            line-height: 1.4;
        }
    }
    &-footer{
        color: #898989;
        max-width: toVw(805);
    }
}