.preloader{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.sk-double-bounce {
  width: 30px;
  height: 30px;
  position: relative;
  margin: auto;

  .sk-child {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $green;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-double-bounce 2.0s infinite ease-in-out;
  }

  .sk-double-bounce-2 {
    animation-delay: -1.0s;
  }
}

@keyframes sk-double-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.0);
  }
}