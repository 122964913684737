.services{
    background-color: $blue;
    position: relative;
    padding-top: toVw(110);
    padding-bottom: toVw(120);
    @include xmd-block(){
        padding-top: toVw(50);
        padding-bottom: toVw(50);
    }
    @include sm-block(){
        padding-top: toVw(30);
        padding-bottom: toVw(30);
    }
    @include xxs-block(){
        padding-top: toVw(20);
        padding-bottom: toVw(20);
    }
    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../img/noise.png");
        background-size: 15px;
        background-repeat: repeat;
    }
    &-wrp{

    }
    &-zag{
        @include font(20);
        color: $white;
        font-weight: 300;
        text-transform: uppercase;
        padding-left: toVw(140);
        @include xmd-block(){
            padding-left: toVw(50);
        }
        @include sm-block(){
            padding-left: toVw(30);
        }
        @include xxs-block(){
            padding-left: toVw(20);
        }
    }
    &-items{

    }
    &-item{
        padding: toVw(90) toVw(140) 0 toVw(140);
        transition: .3s;
        background-color: $blue;
        position: relative;
        margin-top: toVw(1);
        @include xmd-block(){
            padding: toVw(45) toVw(50) 0 toVw(50);
        }
        @include sm-block(){
            padding: toVw(30) toVw(30) 0 toVw(30);
        }
        @include xxs-block(){
            padding-top: toVw(10);
            padding-bottom: toVw(10);
            padding-left: toVw(20);
        }
        &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../img/noise.png");
            background-size: 15px;
            background-repeat: repeat;
        }
        &:after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #282B88;
            opacity: 0;
            transition: .3s;
        }
        &:first-child{
            padding-top: toVw(50);
            @include sm-block(){
                padding-top: toVw(30);
            }
        }
        &__wrp{
            position: relative;
            z-index: 1;
        }
        &__zag{
            @include font(60);
            font-weight: 500;
            color: $white;
            margin-bottom: toVw(45);
            @include xmd-block(){
                line-height: 1;
            }
            @include sm-block(){
                margin-bottom: toVw(30);
            }
            @include xxs-block(){
                @include font(30);
                margin-bottom: toVw(10);
            }
        }
        &__body{
            padding-bottom: toVw(15);
            padding-top: toVw(15);
            border-top: 1px solid #C4C4C4;
            border-bottom: 1px solid #C4C4C4;
            @include xxs-block(){
            display: none;

            }

        }
        &__li{
            display: flex;
            align-items: flex-start;
            padding-top: toVw(30);
            padding-bottom: toVw(30);
            @include xmd-block(){
                padding-top: toVw(20);
                padding-bottom: toVw(20);

            }
            @include sm-block(){
                flex-wrap: wrap;
            }
            @include xxs-block(){
                padding-top: toVw(10);
                padding-bottom: toVw(10);
            }
            &:last-child{
                margin-bottom: 0;
            }
            &:hover{

                .services-item__name{
                    span{
                        padding-left: toVw(10);
                    }

                }
            }
        }
        &__name{
            width: 50%;
            @include xmd-block(){
                width: 30%;
            }
            @include sm-block(){
                width: 100%;
                margin-bottom: toVw(20);
            }
            span{
                @include font(25);
                font-weight: 500;
                color: $white;
                width: 100%;
                max-width: toVw(413);
                transition: .5s;
            }
        }
        &__list{
            width: 50%;
            @include xmd-block(){
                width: 70%;
            }
            @include sm-block(){
                width: 100%;
                margin-bottom: toVw(20);
            }
            li{
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include xxs-block(){
                    flex-wrap: wrap;
                    margin-bottom: toVw(20);
                }
                .name{
                    width: 33.33%;
                    @include xxs-block(){
                        width: 100%;
                    }
            }
                .cash{
                    @include font(20);
                    width: 33.33%;
                    @include sm-block(){
                        width: auto;
                    }
                    @include xxs-block(){
                        width: 100%;
                        padding-top: toVw(5);
                        margin-bottom: toVw(10);
                    }
                }
                a{
                    @include sm-block(){
                        width: auto !important;
                    }
                    .btn-white{
                        border: 0;
                        border-bottom: 1px solid transparent;
                        border-radius: 0;
                        height: auto;
                        padding-bottom: toVw(3);
                        justify-content: left;
                        @include xmd-block(){
                            justify-content: right;
                        }
                        &:hover{
                            background-color: transparent;
                            color: $white;
                            border-color: $white;
                        }
                    }

                }
            }
        }
        &__btn{
            //width: 19%;
            width: auto;
            @include xmd-block(){
                width: 25%;
            }
            @include sm-block(){
                //width: 100%;
                //margin-left: auto;
            }



        }
        &._width{
            .services-item__list{
                width: 32%;
                @include xmd-block(){
                    width: 50%;
                }
                @include sm-block(){
                    width: 100%;
                }
                li{
                    .name{
                        width: 50%;
                        @include xxs-block(){
                            width: 100%;
                        }
                    }
                    .cash{
                        padding-left: toVw(10);
                        @include xxs-block(){
                            padding-left: 0;
                        }
                    }
                }
            }
            .services-item__btn{
                margin-left: auto;
                width: 15%;
                @include sm-block(){
                    margin-left: 0;
                }
                @include xxs-block(){
                    width: 100%;
                }
            }
        }

    }
}