$font: "Formular";
$fontSizeMain: 16;

//COLOR
$black: #202020;
$grey: #3D3D3D;
$white: #fff;
$blue: #000361;
$green: #74BB20;
$purple: #6477b9;
$text: #757575;




$zIndexSup: 9999;
$zIndexH: 3;
$zIndexM: 2;
$zIndexL: 1;

._zIndexM{
  z-index: $zIndexM;
}
._zIndexH{
  z-index: $zIndexH;
}
._zIndexSup{
  z-index: $zIndexSup;
}




.black{
  color: $black;
}
.grey{
  color: $grey;
}
.white{
  color: $white;
}
.blue{
  color: $blue;
}
.green{
  color: $green;
}


//SIZE TO VW
:root {
  --vwIdth: 1920;
  @include xmd-block() {
    --vwIdth: 1100;
  }
  @include sm-block() {
    --vwIdth: 800;
  }
  @include xxs-block() {
    --vwIdth: 375;
  }
}


//MEDIA CLASS
.md-show {
  display: none !important;
}
.xmd-show {
  display: none !important;
}
.sm-show {
  display: none !important;
}
.xs-show {
  display: none !important;
}
.xxs-show {
  display: none !important;
}
@include md-block() {
  .md-show {
    display: flex !important;
  }
  .md-hide {
    display: none !important;
  }
}
@include xmd-block() {
  .xmd-show {
    display: flex !important;
  }
  .xmd-hide {
    display: none !important;
  }
}
@include sm-block() {
  .sm-show {
    display: flex !important;
  }
  .sm-hide {
    display: none !important;
  }
}
@include xs-block() {
  .xs-show {
    display: flex !important;
  }
  .xs-hide {
    display: none !important;
  }
}
@include xxs-block() {
  .xxs-show {
    display: flex !important;
  }
  .xxs-hide {
    display: none !important;
  }
}