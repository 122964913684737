.footer{
	background-color: $blue;
	position: relative;
	&:before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url("../img/noise.png");
		background-size: 15px;
		background-repeat: repeat;
	}
	&-wrp{
		display: flex;
		flex-wrap: wrap;
	}
	&-application{
		width: 100%;
		display: flex;
		justify-content: center;
		padding: toVw(50);
		border-bottom: 1px solid #333581;
		z-index: 1;
		@include xmd-block(){
			padding: toVw(30);
		}
		@include xxs-block(){
			padding: toVw(20) 0;
		}
		.zag{
			display: flex;
			align-items: flex-end;
			@include xxs-block(){
				align-items: center;
			}
			&:hover{
				.icon{
					margin-left: toVw(50);
					margin-right: toVw(5);
				}
			}
			span{
				@include font(60);
				font-weight: 500;
				color: $white;
				@include sm-block(){
					@include font(40);
				}
				@include xxs-block(){
					@include font(25);
				}
			}
			.icon{
				width: toVw(50);
				height: toVw(50);
				fill: $white;
				margin-left: toVw(35);
				margin-right: toVw(20);
				transition: .3s;
				@include xxs-block(){
					width: toVw(30);
					height: toVw(30);
				}
			}
		}
	}
	&-body{
		display: flex;
		width: 100%;
		padding: toVw(55) 0;
		border-bottom: 1px solid #333581;
		@include xmd-block(){
			padding: toVw(30);
		}
		@include sm-block(){
			flex-wrap: wrap;
		}
		@include xxs-block(){
			padding: toVw(20) 0;
		}
	}
	&-links{
		width: 50%;
		margin-right: auto;
		@include xmd-block(){
			width: calc(30% - 30px);
			padding-right: toVw(30);
		}
		@include sm-block(){
			width: 100%;
			order: 2;
		}
		&__logo{
			margin-bottom: toVw(40);
			display: flex;
			@include xxs-block(){
				margin-bottom: toVw(20);
			}
			picture{
				max-width: toVw(260);
				img{
					width: 100%;
					height: 100%;
					object-fit: contain;
					@include xxs-block(){
						width: auto;
					}
				}
			}
		}
		&__wrp{
			display: flex;
			align-items: center;
			a{
				margin-right: toVw(15);
				width: toVw(50);
				height: toVw(50);
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-right: 0;
				position: relative;
				&:before{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: 1px solid $white;
					border-radius: 50%;
					opacity: 1;
					transition: .5s;
				}
				&:hover{
					.icon{
						transform: rotate(0deg);
					}
					&:before{
						opacity: 0;
					}
				}

				&:last-child{
					margin-right: 0;
				}
				.icon{
					width: toVw(25);
					height: toVw(25);
					fill: $white;
					transition: .3s;
					transform: rotate(360deg);
				}
			}
		}
	}
	&-menu{
		display: flex;
		width: 50%;
		z-index: 1;
		@include xmd-block(){
			width: 70%;
		}
		@include sm-block(){
			width: 100%;
			order: 1;
			margin-bottom: toVw(20);
		}
		@include xxs-block(){
			flex-wrap: wrap;
		}
		&__list{
			width: calc(33.33% + 140px);
			padding-right: toVw(140);
			@include xmd-block(){
				padding-right: toVw(20);
			}
			@include sm-block(){
				width: calc(50% - 25px);
			}
			@include xxs-block(){
				width: 100%;
				padding-right: 0;
				&:first-child{
					margin-bottom: toVw(30);
				}
			}
			&-zag{
				@include font(12);
				font-weight: 500;
				text-transform: uppercase;
				color: #ccccdf;
				margin-bottom: toVw(20);
			}
			ul{
				max-width: toVw(255);
				li{
					padding: toVw(6) 0;
					&:hover{
						a{
							margin-left: toVw(10);
						}
					}
					a{
						color: $white;
						font-weight: 500;
						transition: .3s;
					}
					&.email{
						margin: toVw(20) 0;
					}
					&.text{
						span{
							color: $white;
							font-weight: 500;
						}
					}

				}
			}
		}
		&__arr{
			margin-left: auto;
			width: toVw(50);
			height: toVw(50);
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-right: 0;
			position: relative;
			cursor: pointer;
			&:before{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 1px solid $white;
				border-radius: 50%;
				opacity: 1;
				transition: .5s;
			}
			&:hover{
				.icon{
					transform: rotate(0deg);
				}
				&:before{
					opacity: 0;
				}
			}
			.icon{
				width: toVw(15);
				height: toVw(15);
				fill: $white;
				transition: .3s;
				transform: rotate(360deg);
			}
		}
	}
	&-copyright{
		width: 100%;
		padding: toVw(20) 0;
		display: flex;
		justify-content: center;
		z-index: 1;
		span{
			@include font(14);
			color: #b2b3cf;
			transition: .3s;
			&:hover{
				color: $white;
			}
		}
	}
}