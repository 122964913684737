.breadcrumbs{
	width: 100%;
	z-index: 1;
	&._black{
		padding: toVw(20) 0;
	}
	.bx-breadcrumb {
		display: flex;
		flex-wrap: wrap;


		&-item {
			color: $white;
			@include font(14);
			line-height: 150%;



			&:not(:last-child) {
				&::after {
					content: '\2022';
					margin: toVw(5);
					color: $white;
				}
			}
			&._black{
				color: $black;
				&::after{
					color: $black;
				}
			}
		}

		span,
		a {
			font-size: inherit;
			color: inherit;
		}

		a {
			border-bottom: 1px solid transparent;
			transition: .3s;
			&:hover {
				border-color: $white;
			}
		}
	}
}
