.features{
    padding-top: toVw(95);
    background-color: #F8F8F8;
    @include xmd-block(){
        padding-top: toVw(50);
    }
    @include xxs-block(){
        padding-top: toVw(20);
    }
    &-wrp{
        display: flex;
        @include sm-block(){
            flex-wrap: wrap;
        }
    }
    &-img{
        width: 50%;
        @include sm-block(){
            width: 100%;
        }

        &__title{
            @include font(20);
            font-weight: 300;
            color: $text;
            text-transform: uppercase;
            margin-bottom: toVw(85);
            @include xmd-block(){
                margin-bottom: toVw(50);
            }
            @include sm-block(){
                margin-bottom: toVw(20);
            }
        }
        picture{
            max-width: toVw(700);
            padding-bottom: 42%;
            position: relative;
            @include xmd-block(){
                padding-bottom: 51%;
            }
            @include sm-block(){
                padding-bottom: 50%;
                max-width: 100%;
            }
            img{
                @include absolute-center();
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-top-left-radius: 1000px;
                border-top-right-radius: 1000px;
            }
        }
    }
    &-info{
        width: 50%;
        padding: toVw(50) 0 toVw(150);
        margin-left: toVw(20);
        border-top: 1px solid #d0d0d0;
        border-bottom: 1px solid #d0d0d0;
        margin-bottom: toVw(100);
        @include xmd-block(){
            padding-bottom: toVw(50);
            margin-bottom: toVw(50);
        }
        @include sm-block(){
            width: 100%;
            border: none;
            margin-left: 0;
            margin-bottom: 0;
        }
        @include xxs-block(){
            padding: toVw(20) 0;
            margin-bottom: toVw(20);
        }
        &__zag{
            @include font(25);
            font-weight: 700;
            margin-bottom: toVw(15);
        }
        &__desc{
            @include font(18);
            line-height: 140%;
        }
    }
}