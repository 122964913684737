$error-color: red;
$succes-color: green;



.form-style{
	width: 100%;
	border: none;
	background-color: $white;
	border-radius: 0;
	max-width: toVw(804);
	&__item{
		width: 100%;
		margin-bottom: toVw(15);
        position: relative;
		border: 1px solid transparent;
		transition: .3s;
		&.error{
			border: 1px solid #e34f4f;
		}
		input{
			width: 100%;
			padding: toVw(15) toVw(20) toVw(15) toVw(20);
			background-color: #f8f8f8;
			color: #202020;
			@include font(18);
			line-height: 140%;
			border: none;
			box-shadow: none;
            @include xxs-block(){
                padding: toVw(15);
            }
			&::placeholder{
				color: red;
			}
			&::-webkit-input-placeholder {
				color: #838383;
			}

			&::-moz-placeholder {
				color: #838383;
			}

			&:-moz-placeholder {
				color: #838383;
			}

			&:-ms-input-placeholder {
				color: #838383;
			}

			&:-webkit-autofill {
				transition: all 5000s ease-in-out 0s;
				background-color: $white !important;
			}

			&:-webkit-autofill {
				box-shadow: 0 0 0 30px $white inset !important;
			}

			&:-webkit-autofill:hover {
				box-shadow: 0 0 0 30px $white inset !important;
			}

			&:-webkit-autofill:focus {
				box-shadow: 0 0 0 30px $white inset !important;
			}

			&:-webkit-autofill:active {
				box-shadow: 0 0 0 30px $white inset !important;
			}

			&:-internal-autofill-selected {
				background-color: #fff !important;
				box-shadow: inset 0 0 0 50px #fff !important;
				-webkit-box-shadow: inset 0 0 0 50px #fff !important;
				-webkit-text-fill-color: #202020 !important;
				color: #202020 !important;
			}

		}
		textarea{
			width: 100%;
			height: toVw(130);
			margin-bottom: toVw(15);
			padding: toVw(15) toVw(20) toVw(15) toVw(20);
			border: none;
			background-color: #F8F8F8;
			color: #202020;
			@include font(18);
			line-height: 140%;
			&::-webkit-input-placeholder {
				color: #838383;
			}

			&::-moz-placeholder {
				color: #838383;
			}

			&:-moz-placeholder {
				color: #838383;
			}

			&:-ms-input-placeholder {
				color: #838383;
			}
		}
		&.buttons{
			display: flex;
			flex-wrap: wrap;
			label{
				width: 100%;
				display: flex;
				align-items: center;
				margin-bottom: toVw(30);
				.jq-checkbox{
					width: toVw(20);
					height: toVw(20);
					border-radius: 3px;
					border: 1px solid #808080;
					background-color: $white;
					.styler{
						opacity: 0;
					}
				}
				.jq-checkbox.focused{
					background-image: url("../img/svg/checkbox.svg");
					background-size: cover;
					width: toVw(20);
					height: toVw(20);
				}
                .jq-checkbox.checked{
                    background-image: url("../img/svg/checkbox.svg");
                    background-size: cover;
                    width: toVw(20);
                    height: toVw(20);
                }

				span{
					padding-left: toVw(10);
					color: #808080;
				}
			}
			button{
				width: toVw(200);
				height: toVw(50);
				background-color: $blue;
				color: $white;
				@include font(18);
				transition: .3s;
				cursor: pointer;
				&:hover{
					color: $blue;
					background-color: $white;
				}
			}
		}
        &:last-child{
            margin-bottom: 0;
        }
	}

}

::-webkit-input-placeholder {color: #B0B0B0; opacity:1;}/* webkit */
::-moz-placeholder          {color: #B0B0B0; opacity:1;}/* Firefox 19+ */
:-moz-placeholder           {color: #B0B0B0; opacity:1;}/* Firefox 18- */
:-ms-input-placeholder      {color: #B0B0B0; opacity:1;}/* IE */







//input[type="search"]::-webkit-input-placeholder {
//	color: #0c39d9;
//}
//input[type="search"]::-moz-placeholder {
//	color: #0b0fe7;
//}














* {
	box-sizing: border-box;
	border: none;
}

html .mfp-content {
	max-width: 1000px;
	width: 100%;
}
//
//.form-validate-ajax {
//	// padding: 20px 40px;
//	padding-bottom: 0 !important;
//	width: 100%;
//	position: relative;
//	display: inline-block;
//	background-color: #fff;
//	text-align: left;
//}
//
//form {
//	max-width: 1000px;
//	display: flex;
//	flex-wrap: wrap;
//	margin: 0 auto;
//	background-color: #f5f5f5;
//	border: 1px solid #e5e5e5;
//	border-radius: 2px;
//	box-shadow: 1px 1px 10px rgba(0, 0, 0, .05);
//}
//
//.mfp-content {
//	max-width: 375px;
//	margin: 0 auto;
//}
//
//label {
//	display: block;
//	position: relative;
//
//	&:hover {
//		cursor: pointer;
//	}
//
//	img {
//		position: absolute;
//		right: 10px;
//		top: 30px;
//	}
//}
//
//
//
//input[type="text"],
//input[type="tel"],
//input[type="email"],
//input[type="password"],
//textarea {
//	width: 100%;
//	padding: 10px;
//	border: 1px solid #dbdbdb;
//	border-radius: 2px;
//	box-shadow: inset 1px 1px 5px rgba(0, 0, 0, .05);
//	transition: .2s;
//
//	&:focus {
//		// box-shadow:
//		// 	inset 1px 1px 5px rgba(0, 0, 0, .05),
//		// 	0 0 0 1px #80d4ff;
//	}
//}
//
//input[type="submit"] {
//	width: 100%;
//	padding: 10px 20px;
//	background-color: #00bf00;
//	border: none;
//	color: #fff;
//	border-radius: 2px;
//	transition: .2s;
//
//	&:hover {
//		cursor: pointer;
//		background-color: #008000;
//	}
//}
//
//textarea {
//	width: 100%;
//	max-width: 100%;
//}
//
//.input-country {
//	position: relative;
//
//	.jq-selectbox {
//		position: absolute;
//		width: 100px;
//		height: 100%;
//		border: 0;
//
//		&__select {
//			height: 100%;
//			padding: 0;
//			background: none;
//			box-shadow: none;
//			text-shadow: none;
//			border: none;
//			border-radius: 0;
//			border-right: 1px solid #dbdbdb;
//
//
//			&-text {
//				height: 100%;
//				display: flex;
//				align-items: center;
//				padding-left: 40px;
//				background-size: 20px;
//				background-repeat: no-repeat;
//				background-position: left 10px center;
//			}
//		}
//
//		&__trigger {
//			display: flex;
//			justify-content: center;
//			align-items: center;
//			border: none;
//
//			&-arrow {
//				position: static;
//				top: auto;
//				left: auto;
//				right: auto;
//			}
//		}
//
//		&__dropdown {
//			margin: 0;
//			top: 100% !important;
//		}
//
//		li {
//			padding-left: 40px;
//			background-size: 20px;
//			background-repeat: no-repeat;
//			background-position: left 10px center;
//		}
//	}
//
//	input[type="tel"] {
//		padding-left: 110px;
//	}
//
//}
//
//.jq-radio.checked .jq-radio__div {
//	margin: 2px 0 0 2px;
//}
//
//
//
//#ui-datepicker-div {
//	z-index: 10000 !important;
//}