.text-banner{
    padding: toVw(95) 0;
    background-color: #f8f8f8;
    @include xmd-block(){
        padding: toVw(30) 0;
    }
    @include xxs-block(){
        padding: toVw(20) 0;
    }
    &__wrp{
        padding: toVw(50) 0;
        position: relative;
        @include xmd-block(){
            padding: toVw(30) 0;
        }
        @include xxs-block(){
            padding: toVw(20);
        }
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: #8B8B8B;
            opacity: 0.4;
            width: toVw(500);
            height: 1px;
            @include xxs-block(){
                width: toVw(300);
            }
        }
        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: #8B8B8B;
            opacity: 0.4;
            width: toVw(500);
            height: 1px;
            @include xxs-block(){
                width: toVw(300);
            }
        }
    }
    &__info{
        max-width: toVw(1310);
        margin: 0 auto;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        span{
            @include font(35);
            font-weight: 500;
            font-style: italic;
            @include xmd-block(){
                @include font(25);
            }
            @include xxs-block(){
                @include font(18);
            }
        }
        .blue{
            color: $blue;
            transition: .3s;
            border-bottom: 1px solid transparent;
            &:hover{
                border-color: $blue;
            }
        }
    }
}