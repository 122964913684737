.works{
    padding: toVw(100) 0 toVw(55);
    background-color: #f8f8f8;
    @include xmd-block(){
        padding: toVw(40) 0;
    }
    @include xxs-block(){
        padding: toVw(20) 0;
    }
    &-wrp{

    }
    &-head{
        padding-bottom: toVw(75);
        display: flex;
        @include xmd-block(){
            flex-wrap: wrap;
            padding-bottom: toVw(50);
        }
        @include xxs-block(){
            padding-bottom: toVw(20);
        }
        &__zag{
            margin-bottom: toVw(50);
            width: 50%;
            position: relative;
            @include xmd-block(){
                width: 100%;
                margin-bottom: toVw(20);
            }
            span{
                @include font(20);
                font-weight: 300;
                text-transform: uppercase;

                color: $text;
            }
            .works-head__slider-arrows{
                display: flex;
                align-items: center;
                position: absolute;
                top: toVw(67);
                left: 0;
                @include xmd-block(){
                    left: auto;
                    right: toVw(50);
                    top: toVw(125);
                }

                @include xxs-block(){
                    display: none;
                }
                .arr{
                    width: toVw(55);
                    height: toVw(55);
                    border-radius: 50%;
                    background-color: $blue;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: .3s;
                    border: 3px solid $blue;
                    margin-right: toVw(10);
                    cursor: pointer;
                    &:last-child{
                        margin-right: 0;
                    }
                    &:hover{
                        background-color: $white;
                        .icon{
                            fill: $blue;
                        }
                    }
                    .icon{
                        fill: $white;
                        width: toVw(20);
                        height: toVw(20);
                    }
                }
            }
        }
        &__info{
            max-width: toVw(805);
            .title{
                @include font(25);
                font-weight: 700;
                margin-bottom: toVw(15);
            }
            p{
                margin-bottom: toVw(25);
                @include xmd-block(){
                    margin-bottom: toVw(20);
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    &-body{
        &__items{
            position: relative;
            @include xxs-block(){
                width: 100%;
                margin-right: 0;
            }

        }
        &__wrp{
            display: flex;
            align-items: center;
            .slick-dots{
                bottom: toVw(-30);
                li{
                    background-color: $blue;
                    border-radius: 50%;
                    margin: 0 10px;
                    width: toVw(15);
                    height: toVw(15);
                }
                button:before{
                    content: none;
                }
                .slick-active{
                    opacity: 0.4;
                }
            }
        }
        &__item{
            width: 50%;
            margin-right: toVw(45);
            padding: toVw(35) toVw(55) toVw(40);
            display: flex !important;
            max-width: toVw(870);
            flex-wrap: wrap;
            @include xmd-block(){
                padding: toVw(20);
            }
            @include sm-block(){
                max-width: toVw(600);
            }
            @include xxs-block(){
                width: calc(100% - 40px) !important;
                margin: 0 toVw(20);
            }
            &._green{
                background-color: $green;
            }
            &._brown{
                background-color: #B68859;
            }
            &-name{
                @include font(30);
                color: $white;
                font-weight: 500;
                margin-bottom: toVw(25);
                width: 55%;
                text-align: left;
                @include sm-block(){
                    width: 100%;
                }
                @include xxs-block(){
                    @include font(25);
                }
            }
            &-hashtags{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
                margin-bottom: toVw(30);
                min-height: toVw(100);
                @include sm-block(){
                    margin-bottom: toVw(20);
                    min-height: toVw(177);
                }
                @include xxs-block(){
                    margin-bottom: toVw(10);
                    min-height: auto;
                }
                li{
                    padding: toVw(8) toVw(20);
                    border: 2px solid $white;
                    border-radius: 30px;
                    margin-right: toVw(10);
                    margin-bottom: toVw(10);
                    @include xxs-block(){
                        padding: toVw(5) toVw(10);
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    span{
                        color: $white;
                        font-weight: 500;
                        @include font(12);
                        text-transform: uppercase;
                        border-bottom: 1px solid transparent;
                        transition: .3s;
                        @include xxs-block(){
                            //@include font(10);
                            font-weight: 400;
                        }
                    }

                }
            }
            &-link{
                width: 40%;
                display: flex;
                align-items: flex-end;
                @include sm-block(){
                    width: 100%;
                    margin-bottom: toVw(20);
                }

                span{
                    color: $white;
                    text-transform: uppercase;
                    font-weight: 700;
                    transition: .3s;
                    border-bottom: 1px solid transparent;
                    &:hover{
                        border-color: $white;

                    }
                }
            }
            &-img{
                width: 60%;
                overflow: hidden;
                max-height: toVw(240);
                border: 6px solid $black;
                border-radius: 10px;
                @include sm-block(){
                    width: 100%;
                }
                .mCSB_scrollTools{
                    opacity: 1;
                    background-color: $black;
                }
                .mCSB_inside > .mCSB_container{
                    margin-right: toVw(15);
                }
                .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
                    background-color: $white;
                    opacity: 1;
                }
                .mCSB_scrollTools .mCSB_draggerRail{
                    opacity: 1;
                    background-color: $white;
                }
            }
        }
    }

}