.factors{
    background-color: $blue;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../img/noise.png);
        background-size: 15px;
        background-repeat: repeat;
    }
    &-wrp{
        display: flex;
        align-items: flex-start;
        padding-top: toVw(110);
        @include xmd-block(){
            padding-top: toVw(50);
        }
        @include xxs-block(){
            flex-wrap: wrap;
            padding-top: toVw(20);
        }
    }
    &-img{
        width: 50%;
        z-index: 1;
        @include xxs-block(){
            width: 100%;
        }
        &__zag{
           @include font(20);
            color: $white;
            font-weight: 300;
            text-transform: uppercase;
            margin-bottom: toVw(175);
            @include xmd-block(){
                margin-bottom: toVw(50);
            }
            @include xxs-block(){
                margin-bottom: toVw(20);
            }
        }
        picture{
            position: relative;
            width: toVw(600);
            padding-bottom: 72%;
            @include xmd-block(){
                width: toVw(400);
            }
            @include sm-block(){
                width: toVw(300);
            }
            @include xxs-block(){
                display: none;
            }
            &:before{
                content: '';
                position: absolute;
                right: toVw(20);
                top: 50%;
                transform: translateY(-50%);
                width: toVw(685);
                height: toVw(685);
                border-radius: 50%;
                border: 1px solid #6668a0;
                @include xmd-block(){
                    width: toVw(430);
                    height: toVw(430);
                }
                @include sm-block(){
                    width: toVw(300);
                    height: toVw(300);
                }
                @include xxs-block(){
                    content: none;
                }
            }
            &:after{
                content: '';
                position: absolute;
                right: toVw(20);
                top: 50%;
                transform: translateY(-50%);
                border-radius: 50%;
                width: toVw(790);
                height: toVw(790);
                border: 1px solid #333581;
                @include xmd-block(){
                    width: toVw(470);
                    height: toVw(470);
                }
                @include sm-block(){
                    width: toVw(330);
                    height: toVw(330);
                }
                @include xxs-block(){
                    content: none;
                }

              }
            img{
                @include absolute-center();
                width: 100%;
                height: 100%;
                z-index: 2;
            }
        }
    }
    &-info{
        width: 50%;
        padding-right: toVw(107);
        overflow: auto;
        max-height: toVw(980);
        position: relative;
        @include xmd-block(){
            max-height: toVw(550);
            padding-right: 0;
        }
        @include xxs-block(){
            width: 100%;
            max-height: toVw(350);
            padding-bottom: toVw(20);
        }
        &:before{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: toVw(70);
            background: linear-gradient(360deg, #000156 -17.04%, rgba(0, 1, 86, 0) 120.74%);
            z-index: 1;
            @include xxs-block(){
                content: none;
            }
        }
        &__items{

        }
        &__item{
            color: $white;
            margin-bottom: toVw(70);
            @include xmd-block(){
                margin-bottom: toVw(40);
            }
            @include xxs-block(){
                margin-bottom: toVw(20);
            }
            .title{
               @include font(50);
                font-weight: 500;
                margin-bottom: toVw(15);
                @include xmd-block(){
                    @include font(30);
                }
                @include sm-block(){
                    @include font(25)
                }
                @include xxs-block(){
                    @include font(20)
                }
            }
            .subtitle{

            }
        }
        #mCSB_1_scrollbar_vertical{
            opacity: 0;
            @include xxs-block(){
                opacity: 1;
            }
        }
    }
}