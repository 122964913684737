.ms{
    &-wrp{
        display: flex;
        @include sm-block(){
            flex-wrap: wrap;
        }
    }
    &-info{
        width: 50%;
        position: relative;
        background-color: $blue;
        display: flex;
        flex-wrap: wrap;
        //align-items: center;
        padding-right: toVw(55);
        padding-top: toVw(20);
        padding-left: calc((100vw - 87.5vw) / 2);

        @include xmd-block(){
            padding-left: calc((100vw - 90vw) / 2);
        }
        @include sm-block(){
            order: 2;
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
        @include xxs-block(){
            //height: toVw(500);
        }
        &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../img/noise.png");
            background-size: 15px;
            background-repeat: repeat;
        }
        &__absl{
            z-index: 1;
            @include sm-block(){
                transform: none;
                position: relative;
                top: auto;
                left: auto;
                padding: toVw(30);
                z-index: 0;
            }
        }
        &__title{
            @include font(50);
            color: $white;
            font-weight: bold;
            margin-bottom: toVw(25);
            @include xmd-block(){
                @include font(30);
            }
            @include xxs-block(){
                @include font(20);
                margin-bottom: toVw(20);
            }
        }
        &__subtitle{
            @include font(18);
            color: $white;
            margin-bottom: toVw(120);
            line-height: 1.4;
            @include md-block(){
                margin-bottom: toVw(50);
            }
            @include xmd-block(){
                margin-bottom: toVw(30);
            }
            @include xxs-block(){
                margin-bottom: toVw(20);
            }
            &._complete{
                max-width: toVw(670);
                margin-bottom: toVw(40);
            }
        }
        &__awards{
            padding: toVw(55) 0;
            border-top: 1px solid #666794;
            border-bottom: 1px solid #666794;
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: toVw(590);
            @include md-block(){
                max-width: 100%;
            }
            @include xmd-block(){
                padding: toVw(30) 0;
            }
            @include xxs-block(){
                flex-wrap: wrap;
                padding: toVw(20) 0;
            }
            &._ms-complete__awards{
            }
            &-item{
                width: calc(33.33% - 20px);
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                @include xxs-block(){
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                }
                .img{
                    margin-bottom: toVw(20);
                    @include xxs-block(){
                        width: 30%;
                    }
                }
                .text{
                    @include font(14);
                    font-weight: 500;
                    color: $white;
                    text-align: center;
                    max-width: toVw(187);
                    line-height: 1.3;
                    @include md-block(){
                        max-width: 100%;
                    }
                    @include xxs-block(){
                        max-width: 70%;
                    }
                }
            }
            .slogan{
                @include font(25);
                font-weight: 500;
                font-style: italic;
                color: $white;
                max-width: toVw(400);
                line-height: 130%;
            }
        }
    }
    &-img{
        width: 50%;
        display: flex;
        @include sm-block(){
            order: 1;
            width: 100%;
        }
        picture{
            padding-bottom: 100%;
            @include sm-block(){
                padding-bottom: 50%;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include absolute-center();
            }
        }

    }
}