.page-404{
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @include md-block(){
        height: 100%;
        margin-bottom: toVw(100);
    }
    @include xs-block(){
        margin-bottom: toVw(30);
    }
    @include xxs-block(){
        margin-bottom: toVw(20);
    }
    &__info{

        max-width: toVw(535);

    }
    &__number{
        margin-bottom: toVw(40);
        display: flex;
        justify-content: center;
        @include xs-block(){
            margin-bottom: toVw(20);
        }
        picture{
            width: 100%;
            padding-bottom: 35%;
            position: relative;
            img{
                @include absolute-center();
                width: 100%;
                height: 100%;
                object-fit: contain;
                &:not(.loaded) {
                    object-fit: inherit;
                    width: toVw(100);
                    height: toVw(100);
                    transition: 0s;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                }
            }
        }
    }
    &__title{
        @include font(25);
        font-weight: 750;
        color: $black;
        text-align: center;
        margin-bottom: toVw(10);
        &._green{
            color: $green;
            line-height: 1.2;
            font-weight: 750;
            text-transform: uppercase;
            width: calc(100% + 50px);
            margin-left: toVw(-25);
            @include xxs-block(){
                width: 100%;
                margin-left: 0;
            }
        }
        @include xxs-block(){
            @include font(18);
        }
    }
    &__desc{
        @include font(18);
        color: $black;
        text-align: center;

        a{
            color: $blue;
            border-bottom: 1px solid $blue;
            transition: .3s;
            text-decoration: none;
            &:hover{
                opacity: 0.8;
                border-color: $white;
            }
        }
    }
}