.slider{
	margin: 50px 0;
	.container{
		height: 100%;
	}
	&-block{
		height: 300px;
		&:nth-child(odd){
			background-color: #636363;
			.container{
				background-color: #ababab;

			}
		}
		&:nth-child(even){
			background-color: #ababab;
			.container{
				background-color: #636363;

			}
		}
		.container{
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__text{
			color: #fff;
			font-size: 60px;
		}
	}
}