.benefit{
    background-color: $blue;
    padding: toVw(100) 0;
    position: relative;
    @include xmd-block(){
        padding: toVw(50) 0;
    }
    @include xxs-block(){
        padding: toVw(20) 0 toVw(30);
    }
    &:before{content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../img/noise.png);
        background-size: 15px;
        background-repeat: repeat;
    }
    &-wrp{
        display: flex;
        justify-content: space-between;
        position: relative;
        @include sm-block(){
            flex-wrap: wrap;
        }
    }
    &-desc{
        width: 50%;
        padding-right: toVw(140);
        @include xmd-block(){
            padding-right: toVw(50);
        }
        @include sm-block(){
            width: 100%;
            margin-bottom: toVw(50);
            padding-right: 0;
        }
        @include xxs-block(){
            margin-bottom: toVw(20);
        }
        &__zag{
            @include font(20);
            font-weight: 300;
            color: $white;
            text-transform: uppercase;
            margin-bottom: toVw(20);
        }
        &__info{
            @include font(25);
            font-weight: 700;
            color: $white;
            line-height: 140%;
            @include xxs-block(){
                @include font(18);
            }
        }
    }
    &-list{
        width: 50%;
        @include sm-block(){
            width: 100%;
        }
        ul{
            li{
                display: flex;
                align-items: flex-start;
                padding: toVw(27) 0;
                border-bottom: 1px solid $white;
                @include xxs-block(){
                    padding: toVw(20) 0;
                }
                &:first-child{
                    border-top: 1px solid $white;
                }
                span{
                    @include font(18);
                    color: $white;
                    font-weight: 500;
                    line-height: 1.4;
                }
                .number{
                    font-weight: 400;
                    padding-right: toVw(45);
                    @include xxs-block(){
                        padding-right: toVw(10);
                    }
                }
            }
        }
    }

}