.deadlines{
    padding-top: toVw(100);
    @include xmd-block(){
        padding-top: toVw(50);
    }
    @include xxs-block(){
        padding-top: toVw(20);
    }
    &-wrp{

    }
    &-items{
        display: flex;
        align-items: center;
        padding-bottom: toVw(120);
        justify-content: center;
        @include xxs-block(){
            flex-wrap: wrap;
            padding-bottom: toVw(80);
        }
        &._about{
            .deadlines-item__tooltip{
                @include xmd-block(){
                    left: 41%;
                }
                @include sm-block(){
                    left: 0;
                    bottom: toVw(-160);
                }
                @include xxs-block(){
                    bottom: toVw(-50);
                }
            }
        }
        &._contacts{
            .deadlines-item{
                cursor: pointer;
                &__info{
                    max-width: toVw(340);
                    margin-top: toVw(300);
                    transition: .3s;
                    @include xmd-block(){
                        max-width: toVw(200);
                        margin-top: toVw(200);
                    }
                    @include xxs-block(){
                        margin-top: toVw(140);
                    }
                    .zag{
                        @include xmd-block(){
                            @include font(18);
                        }
                    }
                    .desc{
                        color: $white;
                        transition: .3s;
                        p{
                            font-weight: 500;
                            line-height: 1.4;
                            margin-bottom: toVw(10);
                            @include xmd-block(){
                                margin-bottom: toVw(5);
                                @include font(12);
                            }
                            @include sm-block(){
                                @include font(8);
                            }
                            @include xxs-block(){
                                @include font(10);
                            }
                        }
                    }
                }
                &:hover{
                    .deadlines-item__info{
                        margin-top: 0;
                    }
                }
            }

        }
    }
    &-item{
        width: 33.33%;
        border: 1px solid #E4E6EB;
        border-radius: 50%;
        height: toVw(560);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        max-width: toVw(545);
        transition: .3s;
        @include xmd-block(){
            height: toVw(335);
        }
        @include sm-block(){
            height: toVw(253);
        }
        @include xxs-block(){
            width: 100%;
            flex: none;
            height: toVw(335);
        }

        &:hover{
            background-color: $blue;
            .deadlines-item__info{
                .zag{
                    color: $white;
                }
                .number{
                    color: $white;
                }
                span{
                    color: $white;
                }
            }
            .deadlines-item__tooltip{
                opacity: 0.8;
            }
        }
        &__info{
            max-width: toVw(300);
            display: flex;
            flex-wrap: wrap;
            @include sm-block(){
                padding-left: toVw(10);
            }
            .zag{
                @include font(25);
                font-weight: 700;
                margin-bottom: toVw(20);
                transition: .3s;
                @include sm-block(){
                    @include font(17)
                }
            }
            .number{
                @include font(18);
                margin-bottom: toVw(20);
                width: 100%;
                transition: .3s;
            }
            span{
                margin-bottom: toVw(5);
                transition: .3s;
                &:last-child{
                    margin-bottom: 0;
                }
                @include sm-block(){
                    @include font(14)
                }
            }
            .price{
                .blue{
                    color: $blue;
                    font-weight: 500;
                }
                span{

                }
            }

        }
        &__tooltip{
            position: absolute;
            max-width: toVw(370);
            width: 100%;
            background-color: #F3F3F3;
            padding: toVw(20) toVw(25);
            bottom: toVw(-20);
            left: 50%;
            transform: translateX(-41%);
            transition: .3s;
            opacity: 0;
            @include xmd-block(){
                bottom: toVw(-75);
            }
            @include sm-block(){
                left: 0;
                bottom: toVw(-115);
                transform: none;
                padding: toVw(15);
            }
            @include xxs-block(){
                bottom: toVw(-50);
            }
            .zag{
                color: $blue;
                font-weight: 700;
                margin-bottom: toVw(8);
            }
            ul{
                li{
                    position: relative;
                    padding-left: toVw(12);
                    margin-bottom: toVw(5);
                    &:before{
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: toVw(5);
                        height: toVw(5);
                        border-radius: 50%;
                        background-color: $blue;
                    }
                }
            }
        }
    }
}