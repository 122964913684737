.btn-white{
    max-width: toVw(200);
    height: toVw(50);
    border: 1px solid $white;
    color: $white;
    border-radius: 8px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    margin-left: auto;
    @include sm-block(){
        margin-left: 0;
    }
    &:hover{
        color: $blue;
        background-color: $white;
    }
    &._ms-individual__btn{
        margin-left: 0;
        max-width: toVw(310);
    }
    &._ms-complete__btn{
        margin-left: 0;
        max-width: toVw(310);
        margin-bottom: toVw(85);
    }
}