.portfolio{
    background-color: #E5E5E5;
    h1{
        @include font(50);
        font-weight: 700;
        margin-bottom: toVw(60);
        @include xmd-block(){
            margin-bottom: toVw(30);
        }
    }
    &-wrp{
        background-color: $white;
        display: flex;
        padding: toVw(45) toVw(75) toVw(75) toVw(75);
        @include xmd-block(){
            padding: toVw(30);
        }
        @include sm-block(){
            flex-wrap: wrap;
        }
        @include xxs-block(){
            padding: toVw(20);
        }
    }
    &-list{
        width: 30%;
        @include sm-block(){
            width: 100%;
            margin-bottom: toVw(20);
        }
        ul{
            li{
                position: relative;
                margin-bottom: toVw(25);
                @include xxs-block(){
                    margin-bottom: toVw(10);
                }
                &:last-child{
                    margin-bottom: 0;
                }
                a{
                   @include font(18);
                    font-weight: 500;
                    color: $black;
                    transition: .3s;
                    border-bottom: 1px solid transparent;
                    &:hover{
                        color: $blue;
                        border-color: $blue;
                    }
                }
                &._active{
                    padding-left: toVw(12);
                    a{
                        color: $blue;
                        font-weight: 700;
                        border-bottom: none;
                    }
                    &:before{
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: toVw(5);
                        height: toVw(5);
                        border-radius: 50%;
                        background-color: $blue;
                    }
                }
            }
        }
    }
    &-main{
        width: 70%;
        @include sm-block(){
            width: 100%;
        }
    }
    &-title{
        @include font(45);
        font-weight: 700;
        margin-bottom: toVw(25);
        @include xxs-block(){
            @include font(25);
            margin-bottom: toVw(20);
        }

    }
    &-desc{
        max-width: toVw(666);
        margin-bottom: toVw(90);
        @include xmd-block(){
            margin-bottom: toVw(30);
        }
        p{
            margin-bottom: toVw(20);
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &-item{
        margin-bottom: toVw(80);
        @include xmd-block(){
            margin-bottom: toVw(50);
        }
        @include xxs-block(){
            margin-bottom: toVw(30);
        }
        &__head{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: toVw(30);
            border-bottom: 1px solid #e4e4e4;
            margin-bottom: toVw(35);
            @include xmd-block(){
                padding-bottom: toVw(20);
                margin-bottom: toVw(20);
            }
            @include xxs-block(){
                flex-wrap: wrap;
            }
            .name{
                @include font(35);
                font-weight: 700;
                @include xxs-block(){
                    width: 100%;
                    margin-bottom: toVw(10);
                }
            }
            .link{
                font-weight: 700;
                color: $blue;
                transition: .3s;
                border-bottom: 1px solid transparent;
                &:hover{
                    border-color: $blue;
                }
            }
        }
        &__body{
            &-img{
                margin-bottom: toVw(20);
                picture{
                    max-width: toVw(160);
                    padding-bottom: 7%;
                    position: relative;
                    @include xxs-block(){
                        max-width: 100%;
                        padding-bottom: 30%;
                    }
                    img{
                        @include absolute-center();
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            &-list{
                margin-bottom: toVw(25);
                @include xxs-block(){
                    margin-bottom: toVw(20);
                }
                li{
                    margin-bottom: toVw(10);
                    &:last-child{
                        margin-bottom: 0;
                    }
                    span{
                        padding-right: toVw(5);
                    }
                }
            }
            &-desc{
                margin-bottom: toVw(40);
            }
            &-hashtags{
                display: flex;
                align-items: center;
                margin-bottom: toVw(50);
                @include xmd-block(){
                    flex-wrap: wrap;
                    margin-bottom: toVw(20);
                }
                li{
                    padding: toVw(8) toVw(20);
                    border: 2px solid $blue;
                    border-radius: 30px;
                    margin-right: toVw(10);
                    margin-bottom: toVw(10);
                    cursor: default;
                    @include xxs-block(){
                        padding: toVw(5) toVw(10);
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    span{
                        color: $blue;
                        font-weight: 500;
                        @include font(12);
                        text-transform: uppercase;
                        border-bottom: 1px solid transparent;
                        transition: .3s;
                        @include xxs-block(){
                            //@include font(10);
                            font-weight: 400;
                        }
                    }
                }
            }
        }
        &__sl{
            display: flex;
            align-items: center;
            @include xmd-block(){
                flex-wrap: wrap;
                justify-content: center;
            }
            &-ar{
                width: 20%;
                height: toVw(55);
                position: relative;
                cursor: pointer;
                @include xmd-block(){
                    order: 2;
                }
                .arr{
                    @include absolute-center();
                    width: toVw(55);
                    height: toVw(55);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    border: 1px solid $black;
                    transition: .3s;
                    &:hover{
                        background-color: $blue;
                        .icon{
                            fill: $white;
                        }
                    }
                    .icon{
                        width: toVw(20);
                        height: toVw(20);
                        fill: $blue;
                        transition: .3s;
                    }
                }
                .ar-left{
                    @include xmd-block(){
                        order: 2;
                    }

                }
                .ar-right{
                    @include xmd-block(){
                        order: 3;
                    }
                }

            }
            &-img{
                width: 80%;
                cursor: pointer;
                @include xmd-block(){
                    order: 1;
                    width: 100%;
                    margin-bottom: toVw(20);
                }
                .img{

                    picture{
                        width: 100%;
                        padding-bottom: 45%;
                        position: relative;
                        img{
                            @include absolute-center();
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
}











