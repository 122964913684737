.callback {
    background-color: #F7F9FA;
    &-wrp {
        display: flex;
        align-items: center;
        padding: toVw(60) 0 toVw(120);
        @include xmd-block(){
            padding: toVw(50) 0;
        }
        @include xxs-block(){
            flex-wrap: wrap;
            padding: toVw(20) 0;
        }
    }

    &-info {
        width: 50%;
        padding-right: toVw(55);
        @include xmd-block(){
            padding-right: toVw(20);
        }
        @include xxs-block(){
            width: 100%;
        }

        &__zag {
            @include font(50);
            font-weight: 700;
            margin-bottom: toVw(40);
            @include xmd-block(){
                @include font(30);
                margin-bottom: toVw(20);
            }
            @include xxs-block(){
                @include font(25);
            }


        }

        &__contacts {
            padding-bottom: toVw(50);
            border-bottom: 1px solid #d1d1d1;
            margin-bottom: toVw(40);
            @include xmd-block(){
                padding-bottom: toVw(20);
                margin-bottom: toVw(20);
            }
            ul {
                margin-bottom: toVw(40);
                &:last-child{
                    margin-bottom: 0;
                }
                @include xmd-block(){
                    margin-bottom: toVw(20);
                }

                li {
                    margin-bottom: toVw(10);
                    &:last-child{
                        margin-bottom: 0;
                    }

                    a {
                        color: $blue;
                        @include font(30);
                        font-weight: 700;
                        transition: .3s;
                        border-bottom: 1px solid transparent;
                        @include xmd-block(){
                            @include font(20);
                        }
                        &:hover {
                            border-color: $blue;
                        }
                    }

                    b {
                        margin-bottom: toVw(10);
                        line-height: 1.4;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        &__requisites {
            max-width: toVw(500);
            .name{
                @include font(25);
                font-weight: 500;
                line-height: 1.4;
                color: $blue;
                margin-bottom: toVw(10);
            }
            ul{
                li{
                    line-height: 1.4;
                    margin-bottom: toVw(10);
                }
            }
        }
    }
    &-form{
        width: 50%;
        background-color: $white;
        padding: toVw(35) toVw(50) toVw(50) toVw(50);
        @include xmd-block(){
            padding: toVw(20);
        }
        @include xxs-block(){
            width: 100%;
        }
    }
}