.team{
    margin-bottom: toVw(110);
    padding-top: toVw(100);
    @include xmd-block(){
        margin-bottom: toVw(50);
        padding-top: toVw(50);
    }
    @include xxs-block(){
        margin-bottom: toVw(30);
        padding-top: toVw(30);
    }
}
.team-carousel {
    position: relative;
    min-height: 670px;
    transition: 1s;
}

@media screen and (max-width: 576px) {
    .team-carousel {
        margin-left: -16px;
        margin-right: -16px;
    }
}

.team-carousel__wrap {
    position: relative;
    overflow: hidden;
    height: auto !important;
}

.team-carousel__inner {
    position: relative;
    height: auto;
    width: 9999px;
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-transition: -webkit-transform 600ms;
    transition: -webkit-transform 600ms;
    transition: transform 600ms;
    transition: transform 600ms, -webkit-transform 600ms;
    font-size: 0;
}

.team-carousel__controls {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 592px) {
    .team-carousel__controls {
        //display: none;
    }
}

.team-carousel__button {
    position: relative;
    padding: 0;
    line-height: 1;
    background: 0 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    color: #131313;
    line-height: 1.4;
    font-weight: 400;
    width: 55px;
    height: 55px;
    cursor: pointer;
}

.team-carousel__button:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: $blue;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    z-index: 1;
}

.team-carousel__button:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 2;
    background-repeat: no-repeat;
    background-position: 50%;
}

.team-carousel__button--prev:after {
    background-image: url("../img/svg/arr-left.svg");
}

.team-carousel__button--next:after {
    background-image: url("../img/svg/arr-right2.svg");
}

.team-carousel__button:focus {
    outline: none;
}

@media screen and (min-width: 992px) {
    .team-carousel__button:hover:before {
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);

    }
}

.team-carousel__button+.team-carousel__button {
    margin-left: 24px;
}

.team-carousel__cell {
    display: inline-block;
    vertical-align: top;
    margin-right: 16px;
    -webkit-transition: 0.5s width;
    transition: 0.5s width;
}



@media screen and (min-width: 576.98px) {
    .team-carousel__cell {
        width: 24%;
        margin-right: 16px;
    }
}

@media screen and (max-width: 576px) {
    .team-carousel__cell {
        width: 148px;
        padding-left: 16px;
    }
}

@media screen and (min-width: 576.98px) {
    .team-carousel__cell.is-selected {
        width: 36%;
        margin-right: calc(15% - 16px);
    }
}

@media screen and (max-width: 576px) {
    .team-carousel__cell.is-selected {
        width: 148px;
    }
}

.team-carousel .flickity-viewport {
    -webkit-transition: height 0.2s;
    transition: height 0.2s;
}

.team-carousel .flickity-page-dots {
    bottom: 0;
    text-align: right;
}

@media screen and (max-width: 576px) {
    .team-carousel .flickity-page-dots {
        display: none;
    }
}

.team-carousel .flickity-page-dots .dot {
    width: 16px;
    height: 16px;
    margin: 0 4px;
    border: 1px solid #c4c4c4;
    opacity: 1;
    background: transparent;
}

.team-carousel .flickity-page-dots .dot.is-selected {
    background: #131313;
    border-color: #131313;
}

.team-item__thumb{
    margin-bottom: toVw(20);
    position: relative;
    padding-bottom: 100%;
}
.team-item__thumb img{
    transition: .5s;
    //max-height: 390px;
    //height: 390px;
    @include absolute-center();
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.team-item__title{

}
.team-item__title .name{
    @include font(20);
    font-weight: 700;
    color: $black;
    margin-bottom: toVw(5);
}
.team-item__title .post{
    @include font(16);
    color: $text;
}
.team-carousel__cell.active .team-item__thumb img{
    //height: 666px;
    //max-height: 100%;
}
@media screen and (max-width: 1680px){
    .team-carousel{
        min-height: 500px;
    }
}
@media screen and (max-width: 1440px){
    .team-carousel{
        min-height: 500px;
    }
}
@media screen and (max-width: 1180px){
    .team-carousel{
        min-height: 455px;
    }
}
@media screen and (max-width: 990px){
    .team-carousel{
        min-height: 425px;
    }

}
@media screen and (max-width: 800px){
    .team-carousel{
        min-height: 355px;
    }
}
@media screen and (max-width: 600px){
    .team-carousel{
        min-height: 500px;
    }
    .team-carousel__cell .team-item__thumb{
        transition: .3s;
    }
    .team-carousel__cell.active .team-item__thumb{
        padding-bottom: 110%;
    }
    //.team-carousel__cell.active .team-item__thumb img{
    //    height: 250px;
    //}
}
@media screen and (max-width: 525px){
    .team-carousel{
        min-height: 450px;
    }
}
@media screen and (max-width: 380px){
    .team-carousel{
        min-height: 380px;
    }
}






















