.popup-callback{
    padding: toVw(35) toVw(50) toVw(50) toVw(50);
    background-color: $white;
    max-width: toVw(804);
    margin: 0 auto;
    position: relative;
    @include sm-block(){
        padding: toVw(20);
    }
    &__title{
        @include font(25);
        margin-bottom: toVw(15);
        font-weight: 700;
    }
    &__subtitle{
        margin-bottom: toVw(35);
        @include sm-block(){
            margin-bottom: toVw(20);
        }
    }
    &__form{
        .buttons{
            .btn{
                border-radius: 8px;
            }
        }
    }
    .mfp-close{
        top: toVw(5);
        right: toVw(5);
        width: toVw(50);
        height: toVw(50);
        font-size: toVw(35);
        transition: .3s;
        &:hover{
            transform: rotate(180deg);
        }
    }
}