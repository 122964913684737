.specialists{
    padding: toVw(105) 0 toVw(120);
    @include xmd-block(){
        padding: toVw(50) 0;
    }
    @include xxs-block(){
        padding: toVw(20) 0;
    }
    .advantages-top{
        margin-bottom: toVw(60);
    }
    &-wrp{

    }
    &-items{
        display: flex;
        flex-wrap: wrap;
    }
    &-item{
        width: 25%;
        border-radius: 50%;
        border: 1px solid #E4E6EB;
        transition: .3s;
        height: toVw(410);
        display: flex;
        align-items: center;
        @include xmd-block(){
            width: 33.33%;
            height: toVw(330);
        }
        @include sm-block(){
            width: 50%;
        }
        @include xxs-block(){
            width: 100%;
        }
        &:hover{
            background-color: $blue;
            .number{
                color: $white;
            }
            .prof{
                color: $white;
            }
            .desc{
                opacity: 1;

                pointer-events: visible;
            }
        }
        &__info{
            position: relative;
            padding-left: toVw(55);
            max-width: toVw(355);
            .number{
                @include font(18);
                margin-bottom: toVw(20);
                transition: .3s;
            }
            .prof{
                @include font(25);
                font-weight: 500;
                transition: .3s;
            }
            .desc{
                opacity: 0;
                pointer-events: none;
                width: toVw(370);
                position: absolute;
                left: toVw(80);
                bottom: calc(-100% - 70px);
                background-color: rgba(243, 243, 243, 0.8);
                padding: toVw(30) toVw(35);
                @include xmd-block(){
                    padding: toVw(20);
                    width: toVw(310);
                    left: toVw(10);
                    bottom: calc(-100% - 40px);
                }
                @include xxs-block(){
                    bottom: calc(-100% - 50px);
                }

            }
        }
    }
}