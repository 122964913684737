.header{
	position: relative;
	border-bottom: 1px solid #BEBEBE;
	z-index: 2;
	&-wrp{
		display: flex;
		align-items: center;
		justify-content: space-between;
		//padding: toVw(15) 0;
	}
	&-logo{
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: toVw(180);
		width: 100%;
		@include xxs-block(){
			max-width: toVw(100);
		}
		picture{
			padding-bottom: 42%;

			img{
				@include absolute-center();
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
	&-menu{
		&__list{
			display: flex;
			align-items: center;
			@include xmd-block(){
				flex-wrap: wrap;
			}
			li{
				@include xmd-block(){
					width: 100%;
					margin-bottom: toVw(20);
				}
				&:hover{
					a{
						color: $blue;
					}
					.header-menu__body{
						opacity: 1;
						overflow: visible;
						visibility: visible;
					}
				}
				a{
					@include font(18);
					font-weight: 500;
					color: $black;
					padding: toVw(30) toVw(20);
					transition: .3s;
					@include xmd-block(){
						padding: 0;
					}
				}
				.header-menu__body{
					opacity: 0;
					overflow: hidden;
					visibility: hidden;
					transition: .3s;
					position: absolute;
					left: 0;
					width: 100%;
					display: flex;
					justify-content: center;
					padding: toVw(70) 0;
					box-shadow: 0px 13px 15px rgba(0, 0, 0, 0.08);
					z-index: 2;
					background-color: $white;
					@include xmd-block(){
						display: none;
					}
					li{
						padding: 0 toVw(80);
						border-right: 1px solid #BEBEBE;
						&:last-child{
							border-right: none;
							a{

							}

						}
						a{
							display: flex;
							flex-wrap: wrap;
							justify-content: center;
							padding: 0;
							.icon{
								width: toVw(60);
								height: toVw(60);
								fill: $blue;
								margin-bottom: toVw(20);
								transition: .3s;
							}
							span{
								width: 100%;
								text-align: center;
								color: $black;
								transition: .3s;
							}
							&:hover{
								.icon{
									fill: $purple;
								}
								span{
									color: $blue;
								}
							}
						}

					}
				}
				&:first-child{
					a{
						padding-left: 0;
					}
				}
				&:last-child{
					a{
						padding-right: 0;
					}
				}
			}
		}
	}
	&-contacts{
		position: relative;
		padding: toVw(30) 0;
		&__head{
			display: flex;
			align-items: center;
		}
		&:hover{
			.black{
				color: $blue;
			}
			.blue{
				color: $black;
			}
			.icon{
				transform: rotate(180deg);
			}
			.header-contacts__body{
				opacity: 1;
				overflow: visible;
				visibility: visible;
			}
		}
		a{
			display: flex;
			align-items: center;
			font-weight: bold;
			@include font(18);
			padding-right: 10px;
			@include xxs-block(){
				@include font(16)
			}
			.black{
				color: $black;
				transition: .3s;
			}
			.blue{
				color: $blue;
				padding-left: 5px;
				transition: .3s;
			}

		}
		.icon{
			width: toVw(10);
			height: toVw(10);
			fill: $black;
			transition: .3s;
			@include xmd-block(){
				display: none;
			}
		}
		&__body{
			overflow: hidden;
			opacity: 0;
			visibility: hidden;
			flex-wrap: wrap;
			position: absolute;
			top: 100%;
			left: toVw(-45);
			//width: toVw(315);
			transition: .3s;
			box-shadow: 0px 13px 15px rgba(0, 0, 0, 0.08);
			padding: toVw(35) toVw(45) toVw(40) toVw(45);
			background-color: $white;
			@include xmd-block(){
				overflow: visible;
				opacity: 1;
				visibility: visible;
				position: relative;
				//box-shadow: none;
				top: auto;
				left: auto;
				padding: toVw(20) 0 0 0;
				box-shadow: none;
			}
			.tel{
				margin-bottom: toVw(10);
				&:hover{
					a{
						margin-left: toVw(10);
					}
				}
				a{
					color: $blue;
					font-weight: bold;
					transition: .3s;
				}
				&:hover{
					a{
						color: $black;
					}
				}
				&:last-child{
					margin-bottom: toVw(20);
				}
			}
			.email{
				margin-bottom: toVw(20);
				&:hover{
					a{
						color: $black;
						margin-left: toVw(10);
					}
				}
				a{
					color: $blue;
					font-weight: bold;
					transition: .3s;

				}
			}
			.chart{

			}
			//.address{
			//	max-width: toVw(226);
			//}
			.text{
				color: $black;
				font-weight: bold;
				margin-bottom: toVw(20);
			}
			.social{
				display: flex;
				align-items: center;
				margin-bottom: toVw(20);
				width: 100%;
				a{
					margin-right: toVw(15);
					width: toVw(30);
					height: toVw(30);
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					padding-right: 0;
					position: relative;
					&:before{
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						border: 1px solid $blue;
						border-radius: 50%;
						opacity: 1;
						transition: .5s;
					}
					&:hover{
						.icon{
							fill: $black;
							transform: rotate(0deg);
						}
						&:before{
							opacity: 0;
						}
					}

					&:last-child{
						margin-right: 0;
					}
					.icon{
						width: toVw(15);
						height: toVw(15);
						fill: $blue;
						transition: .3s;
						transform: rotate(360deg);
					}
				}
			}
			.btn-blue{
				min-width: toVw(220);
				width: 100%;
				height: toVw(50);
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid $blue;
				border-radius: 8px;
				color: $blue;
				background: none;
				//border: 0;
				//box-shadow: inset 0 0 0 2px $blue;
				position: relative;
				padding-right: 0;
				@include font(16);
				@include xmd-block(){
					max-width: toVw(220);
				}
				&:hover {
					color: $white;
					background-color: $blue;
				}
				//&:before,
				//&:after {
				//	content: "";
				//	position: absolute;
				//	top: 0;
				//	left: 0;
				//	height: 100%;
				//	width: 100%;
				//	transform-origin: center;
				//}
				//
				//
				//&:before {
				//	border-top: 2px solid $purple;
				//	border-bottom: 2px solid $purple;
				//	transform: scale3d(0,1,1);
				//}
				//
				//&:after {
				//	border-left: 2px solid $purple;
				//	border-right: 2px solid $purple;
				//	transform: scale3d(1,0,1);
				//}

				//&:hover:before,
				//&:hover:after {
				//	transform: scale3d(1,1,1);
				//	transition: transform 0.7s;
				//}
			}
		}
	}
	&-burger{
		display: none;
		width: toVw(90);
		//background-color: var(--blue);
		position: relative;
		height: toVw(75);
		align-items: center;
		justify-content: center;
		z-index: 103;
		cursor: pointer;
		@include xmd-block() {
			display: flex;
		}
		@include xxs-block() {
			width: toVw(40);
			height: toVw(55);
		}

		&__ico {
			position: relative;
			width: toVw(50);
			height: toVw(15);
			@include xxs-block(){
				width: toVw(35);
			}

			span {
				position: absolute;
				right: 0;
				width: 100%;
				background: $blue;
				border-radius: 8px;
				height: 3px;
				transition: .3s;
				top: 0;

				&:nth-child(2) {
					width: 50%;
					margin-top: toVw(11);
				}
			}

			&.open {
				span {
					height: toVw(2);
					top: toVw(5);
					right: toVw(8);

					&:nth-child(1) {
						width: 70%;
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						transform: rotate(-45deg);
						width: 70%;
						margin-top: 0;
					}
				}
			}
		}
	}
	&-mob{
		display: none;
		position: absolute;
		top: 0;
		width: calc(100% + 1px);
		right: toVw(-1);
		background-color: var(--white);
		overflow: auto;
		transition: .4s;
		transform: scale(0);
		z-index: 102;
		transform-origin: right top;
		padding: toVw(20);
		@include xmd-block() {
			display: block;
		}
		&.open {
			top: calc(100% - 1px);
			transform: scale(1);
			background-color: $white;
			box-shadow: 0px 13px 15px rgba(0, 0, 0, 0.08);
			right: 0;
		}
	}
}