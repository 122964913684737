.page-ie{
    height: 100vh;
    width: 100%;
    background-color: $blue;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../img/noise.png");
        background-size: 15px;
        background-repeat: repeat;
    }
    &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("/img/ie-line.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

    }
    &__info{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 825px;
        z-index: 2;
    }
    &__logo{
        width: 250px;
        margin: 0 auto 35px;
        z-index: 2;
    }
    &__title{
        font-size: 25px;
        color: #fff;
        font-weight: 750;
        text-align: center;
        margin-bottom: 20px;
    }
    &__desc{
        font-size: 18px;
        color: #fff;
        text-align: center;
    }
}