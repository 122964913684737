.ms-individual{
    position: relative;
    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../img/noise.png);
        background-size: 15px;
        background-repeat: repeat;
    }

    .ms-info{
        &__subtitle{
            margin-bottom: toVw(50);
            @include xmd-block(){
                margin-bottom: toVw(20);
            }
        }
        &__list{
            max-width: toVw(295);
            padding: toVw(50) 0;
            border-top: 1px solid #66679a;
            border-bottom: 1px solid #66679a;
            margin-bottom: toVw(60);
            @include xmd-block(){
                padding: toVw(20) 0;
                margin-bottom: toVw(30);
            }
        }

    }
}