@mixin absolute-center() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}

@mixin triangle($width, $height, $bg, $direction) {
	width: 0;
	height: 0;
	border-style: solid;
	@if $direction == top {
		border-width: 0 $width / 2 + px $height + px $width / 2 + px;
		border-color: transparent transparent $bg transparent;
	}
	@if $direction == right {
		border-width: $height / 2 +px 0 $height / 2 +px $width +px;
		border-color: transparent transparent transparent $bg;
	}
	@if $direction == bottom {
		border-width: $height + px $width / 2 + px 0 $width / 2 + px;
		border-color: $bg transparent transparent transparent;
	}
	@if $direction == left {
		border-width: $height / 2 + px $width + px $height / 2 + px 0;
		border-color: transparent $bg transparent transparent;
	}
	@if $direction == topLeft {
		border-width: $height + px $width + px 0 0;
		border-color: $bg transparent transparent transparent;
	}
	@if $direction == topRight {
		border-width: 0 $width + px $height + px 0;
		border-color: transparent $bg transparent transparent;
	}
	@if $direction == bottomLeft {
		border-width: $height + px 0 0 $width + px;
		border-color: transparent transparent transparent $bg;
	}
	@if $direction == bottomRight {
		border-width: 0 0 $height + px $width + px;
		border-color: transparent transparent $bg transparent;
	}
}

@mixin border-radius($topLeft, $topRight, $bottomRight, $bottomLeft) {
	-webkit-border-radius: $topLeft $topRight $bottomRight $bottomLeft;
	-moz-border-radius: $topLeft $topRight $bottomRight $bottomLeft;
	-ms-border-radius: $topLeft $topRight $bottomRight $bottomLeft;
	-o-border-radius: $topLeft $topRight $bottomRight $bottomLeft;
	border-radius: $topLeft $topRight $bottomRight $bottomLeft;
}

@mixin lh($fontSize,$lineHeight) {
	line-height: $lineHeight / $fontSize;
}


@mixin font($fontSize) {
	font-size: $fontSize / $fontSizeMain + rem;
}



@function toVw($n) {
	$width: var(--vwIdth);
	@return calc(100vw / (#{$width} / #{$n}));
}

@function toVwVal($n) {
	@return 100vw / (1920px / $n);
}

@function toVwXmdVal($n) {
	@return 100vw / (1100px / $n);
}

@function toVwSmVal($n) {
	@return 100vw / (800px / $n);
}

@function toVwXxsVal($n) {
	@return 100vw / (375px / $n);
}


