.development-prof{
    &__wrp{
        //padding: toVw(120) 0 toVw(140);
        padding: toVw(120) 0 toVw(80);
        overflow: hidden;
        @include xmd-block(){
            padding: toVw(50) 0;
        }
        @include xxs-block(){
            padding: toVw(20) 0;
        }
    }
    &__head{
        position: relative;
        width: 100%;
        text-align: left;
        margin-bottom: toVw(65);
        @include sm-block(){
            margin-bottom: toVw(80);
        }
        @include xxs-block(){
            margin-bottom: toVw(20);
        }
    }
    &__title{
        @include font(45);
        font-weight: 700;
        max-width: toVw(760);
        @include sm-block(){
            @include font(30);
        }
        @include xxs-block(){
            @include font(25);
        }
    }
    &__arrows{
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0;
        right: 0;
        @include sm-block(){
            bottom: toVw(-50);
        }
        @include xxs-block(){
            display: none;
        }
    }
    &__arrow{
        width: toVw(55);
        height: toVw(55);
        border: 1px solid $blue;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-right: toVw(10);
        transition: .3s;
        &:last-child{
            margin-right: 0;
        }
        &:hover{
            background-color: $blue;
            .icon{
                fill: $white;
            }
        }
        .icon{
            width: toVw(20);
            height: toVw(20);
            fill: $blue;
        }
    }
    &__body{

    }
    &__items{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            left: toVw(-140);
            top: 0;
            height: 100%;
            width: toVw(140);
            background-color: $white;
            z-index: 1;
        }
        .slick-list{
            overflow: visible;
            width: 100%;
        }
        .slick-dots{
            bottom: toVw(-35);
            li{
                width: toVw(15);
                height: toVw(15);
                margin: 0 toVw(10);
                background-color: $blue;
                border-radius: 50%;
                &.slick-active{
                    opacity: 0.4;
                }
            }
        }

    }
    &__item{
        max-width: toVw(356);
        @include xxs-block(){
            max-width: 100%;
            padding-right: toVw(50);
        }
        .icon{
            width: toVw(95);
            height: toVw(45);
            fill: $blue;
        }
        .zag{
            @include font(25);
            font-weight: 500;
            margin-bottom: toVw(20);
            margin-top: toVw(10);
        }
        .desc{
            color: $grey;
        }
        &._one{
            margin-top: toVw(15);
        }
    }
}