.prices{
    //padding-top: toVw(55);
    padding-bottom: toVw(225);
    @include xmd-block(){
        padding-bottom: toVw(50);
    }
    @include sm-block(){
        padding-bottom: toVw(30) ;
    }
    @include xxs-block(){
        padding-bottom: toVw(20);
    }
    &-wrp{
        display: flex;
        @include sm-block(){
            flex-wrap: wrap;
        }
    }
    &-info{
        width: 50%;
        position: relative;
        @include sm-block(){
            width: 100%;
            margin-bottom: toVw(30);
        }
        &:before{
            content: "";
            position: absolute;
            top: toVw(-55);
            right: toVw(300);
            width: toVw(680);
            height: toVw(680);
            border-radius: 50%;
            background-color: #EEF2F5;
            opacity: 0.4;
            @include xmd-block(){
                top: toVw(-30);
                right: toVw(10);
                width: toVw(580);
                height: toVw(580);
            }
            @include sm-block(){
                top: toVw(-10);
                right: toVw(405);
                width: toVw(560);
                height: toVw(560);
            }
        }
    }
    &-title{
        @include font(20);
        font-weight: 300;
        color: $text;
        text-transform: uppercase;
        margin-bottom: toVw(135);
        position: relative;
        @include xmd-block(){
            margin-bottom: toVw(50);
        }
        @include xxs-block(){
            margin-bottom: toVw(20);
        }
    }
    &-list{
        max-width: toVw(500);
        position: relative;
        @include xs-block(){
            max-width: 100%;
        }
        &__zag{
            @include font(25);
            font-weight: 500;
            margin-bottom: toVw(35);
            @include xxs-block(){
                margin-bottom: toVw(20);
                @include font(20);
            }
        }
        ul{
            li{

            }
            .prices-link{
                //margin-bottom: toVw(10);
                &:hover{
                    a{
                        padding-left: toVw(10);
                    }

                }
                &:nth-child(3){
                    margin-bottom: toVw(25);
                    @include xxs-block(){
                        margin-bottom: toVw(20);
                    }
                }
                a{
                    color: $blue;
                    @include font(30);
                    font-weight: 700;
                    transition: .3s;
                    padding: toVw(5) 0;
                    @include xxs-block(){
                        @include font(20)
                    }
                }
            }
            .text{
                font-weight: 700;
                margin-bottom: toVw(15);
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    &-form{
        width: 50%;
        @include sm-block(){
            width: 100%;
        }
        @include xs-block(){
            z-index: 2;
        }
        &__title{
            @include font(25);
            font-weight: 700;
            margin-bottom: toVw(15);
        }
        &__subtitle{
            color: #2d2d2d;
            margin-bottom: toVw(35);
            @include xxs-block(){
                margin-bottom: toVw(20);
            }
        }

    }
}